import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const getSelectItems = () => {
  const options = [
    <option value="">-- Seleccione un rol --</option>,
    <option value="Titular">Titular</option>,
    <option value="Cotitular">Cotitular</option>,
    <option value="Avalista 1">Avalista 1</option>,
    <option value="Avalista 2">Avalista 2</option>
  ];
  return options;
};

export default ({ handleSubmit, handleUpdate, style, fieldsError }) => (
  <form
    noValidate
    autoComplete="off"
    className={style.form}
    method="post"
    onSubmit={event => {
      handleSubmit(event);
    }}
  >
    <Box display="flex" flexDirection="column">
      <TextField
        error={fieldsError.id}
        variant="outlined"
        required
        id="id"
        label="ID"
        placeholder="1546110"
        name="id"
        className={style.input}
        onChange={handleUpdate}
      />
    </Box>
    <Divider variant="middle" className={style.divider} />
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      css={{ justifyContent: "space-between" }}
    >
      <TextField
        error={fieldsError.dni1}
        variant="outlined"
        required
        id="dni1"
        label="NIF/NIE"
        placeholder="51512109K"
        name="dni1"
        className={style.input}
        onChange={handleUpdate}
      />
      <TextField
        error={fieldsError.csv1}
        variant="outlined"
        required
        id="csv1"
        label="CSV"
        placeholder="L77CLZ8979RK49JC"
        name="csv1"
        className={style.input}
        onChange={handleUpdate}
      />
      <FormControl required variant="outlined" className={style.input}>
        <Select
          error={fieldsError.cargo1}
          native
          onChange={handleUpdate}
          inputProps={{
            name: "cargo1",
            id: "cargo1"
          }}
        >
          {getSelectItems()}
        </Select>
      </FormControl>
    </Box>
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      css={{ justifyContent: "space-between" }}
    >
      <TextField
        error={fieldsError.dni2}
        variant="outlined"
        id="dni2"
        label="NIF/NIE"
        placeholder="89250542P"
        name="dni2"
        className={style.input}
        onChange={handleUpdate}
      />
      <TextField
        variant="outlined"
        id="csv2"
        label="CSV"
        placeholder="L77CLZ8979RK49JC"
        name="csv2"
        className={style.input}
        onChange={handleUpdate}
      />
      <FormControl variant="outlined" className={style.input}>
        <Select
          native
          onChange={handleUpdate}
          inputProps={{
            name: "cargo2",
            id: "cargo2"
          }}
        >
          {getSelectItems()}
        </Select>
      </FormControl>
    </Box>
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      css={{ justifyContent: "space-between" }}
    >
      <TextField
        error={fieldsError.dni3}
        variant="outlined"
        id="dni3"
        label="NIF/NIE"
        placeholder="42123476B"
        name="dni3"
        className={style.input}
        onChange={handleUpdate}
      />
      <TextField
        variant="outlined"
        id="csv3"
        label="CSV"
        placeholder="L77CLZ8979RK49JC"
        name="csv3"
        className={style.input}
        onChange={handleUpdate}
      />
      <FormControl variant="outlined" className={style.input}>
        <Select
          native
          onChange={handleUpdate}
          inputProps={{
            name: "cargo3",
            id: "cargo3"
          }}
        >
          {getSelectItems()}
        </Select>
      </FormControl>
    </Box>
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      css={{ justifyContent: "space-between" }}
    >
      <TextField
        error={fieldsError.dni4}
        variant="outlined"
        id="dni4"
        label="NIF/NIE"
        placeholder="56299999R"
        name="dni4"
        className={style.input}
        onChange={handleUpdate}
      />
      <TextField
        variant="outlined"
        id="csv4"
        label="CSV"
        placeholder="L77CLZ8979RK49JC"
        name="csv4"
        className={style.input}
        onChange={handleUpdate}
      />
      <FormControl variant="outlined" className={style.input}>
        <Select
          native
          onChange={handleUpdate}
          inputProps={{
            name: "cargo4",
            id: "cargo4"
          }}
        >
          {getSelectItems()}
        </Select>
      </FormControl>
    </Box>
    <Divider variant="middle" className={style.divider} />
    <Box display="flex" flexDirection="column" css={{ alignItems: "center" }}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={style.submit}
      >
        Enviar
      </Button>
    </Box>
  </form>
);
