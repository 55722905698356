import React                      from 'react'
import { navigate }               from 'gatsby'
import CssBaseline                from '@material-ui/core/CssBaseline'
import Container                  from '@material-ui/core/Container'
import { withStyles }             from '@material-ui/core/styles'
import Slide                      from '@material-ui/core/Slide'
import Button                     from '@material-ui/core/Button'
import Box                        from '@material-ui/core/Box'
import ExitToApp                  from '@material-ui/icons/ExitToApp'

import Form                       from './Form'
import SnackBar                   from './SnackBar'
import Copyright                  from '../Copyright'

import { logout }                 from '../../utils/auth'

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(1, 0),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  input: {
    flex: 1,
    minWidth: 250,
    maxWidth: 275,
    margin: theme.spacing(1, 0),
  },
  submit: {
    margin: theme.spacing(1, 0),
  },
  margin: {
    margin: theme.spacing(1),
  },
  icon: {
    fontSize: 35,
  },
})

function DNIcalculateChar(digitos) {
  // eslint-disable-next-line radix
  const char = parseInt(digitos) % 23
  const listaCaracteres = 'TRWAGMYFPDXBNJZSQVHLCKE'
  return char < 23 ? listaCaracteres[char] : undefined
}

function DNItransformNIE(digitos) {
  let letra = digitos.charAt(0)
  const resto = digitos.slice(1)
  letra = letra.toUpperCase()
  switch (letra) {
    case 'X': return `0${resto}`
    case 'Y': return `1${resto}`
    case 'Z': return `2${resto}`
    default: return undefined
  }
}

class Proposal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isSnackVisible: false,
      formValid: false,
      fieldsError: {},
      fields: {
        id: { isRequired: true, validation: 'string' },
        dni1: { isRequired: true, validation: 'dni' },
        csv1: { isRequired: true, validation: 'string' },
        cargo1: { isRequired: true, validation: 'string' },
      },
    }
  }

  handleUpdate = (event) => {
    const { target: { value } } = event
    const nextValue = value
    let validation = 'string'
    if (event.target.name.includes('dni')) validation = 'dni'
    const nextField = { [event.target.name]: { value: nextValue, validation, isRequired: event.target.required } }
    this.setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        ...nextField,
      },
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.validateForm()
  }

  validateForm() {
    const { fields } = this.state
    let fieldsError = {}
    const validatedFields = Object.entries(fields).map(([inputKey, inputDetails]) => {
      const { value, validation, isRequired } = inputDetails
      let isValid = false
      let nifValid = false
      switch (validation) {
        case 'dni':
          if (value && value !== '') {
            // eslint-disable-next-line radix
            if (!Number.isNaN(parseInt(value.slice(0, 8)))) {
              const numero = value.slice(0, 8)
              const letra = value.slice(-1)
              const letraCalculada = DNIcalculateChar(numero)
              nifValid = letra.toUpperCase() === letraCalculada && value.match(/^\d{8}[A-Z]$/)
            } else {
              const NIE = value.slice(0, 8)
              const letra = value.slice(-1)
              const numero = DNItransformNIE(NIE)
              const letraCalculada = DNIcalculateChar(numero)
              nifValid = letra.toUpperCase() === letraCalculada && value.match(/^[XYZ]\d{7,8}[A-Z]$/)
            }
            isValid = nifValid || value.match(/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[0-9A-J]$/)
          }
          break
        case 'string':
          isValid = value && value !== ''
          break
        default:
          break
      }
      let fieldError = { [inputKey]: !isValid }
      if (isRequired) fieldError = { [inputKey]: !isValid && isRequired }
      fieldsError = { ...fieldsError, ...fieldError }
      if (isRequired) return isRequired && isValid
      return isValid
    })
    this.setState({ formValid: validatedFields.length > 0 && validatedFields.every((field) => field), fieldsError, isSnackVisible: true }, () => this.submitForm())
  }

  submitForm() {
    const { formValid, fields } = this.state
    if (formValid) {
      window.localStorage.bmw_proposal = JSON.stringify(fields)
      setTimeout(() => navigate('/results'), 3000)
    }
  }

  render() {
    const { classes } = this.props
    const { isSnackVisible, formValid, fieldsError } = this.state

    const snackMessage = formValid ? '¡Listo! Vamos a procesar tu solicitud. Redirigiendo...' : '¡Vaya! Parece que falta algo...Revisa los campos e inténtalo de nuevo'
    const snackType = formValid ? 'success' : 'warning'

    return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <Box
            display="flex"
            flexDirection="row"
            css={{ justifyContent: 'flex-end', alignItems: 'center' }}
          >
            <Button onClick={() => logout(() => navigate('/login'))}>
              <ExitToApp className={classes.icon} />
              Cerrar sesión
            </Button>
          </Box>
          <Form
            fieldsError={fieldsError}
            handleUpdate={(e) => this.handleUpdate(e)}
            handleSubmit={(e) => this.handleSubmit(e)}
            style={{ form: classes.form, divider: classes.divider, input: classes.input }}
          />
          <SnackBar
            variant={snackType}
            className={classes.margin}
            message={snackMessage}
            open={isSnackVisible}
            onClose={() => this.setState({ isSnackVisible: false })}
            TransitionComponent={<Slide direction="down" />}
          />
          <Copyright />
        </div>
      </Container>
    )
  }

}

export default withStyles(styles)(Proposal)
