import React                           from 'react'
import { withStyles }                  from '@material-ui/core/styles'
import CheckCircleIcon                 from '@material-ui/icons/CheckCircle'
import CloseIcon                       from '@material-ui/icons/Close'
import { amber, green }                from '@material-ui/core/colors'
import IconButton                      from '@material-ui/core/IconButton'
import Snackbar                        from '@material-ui/core/Snackbar'
import SnackbarContent                 from '@material-ui/core/SnackbarContent'
import WarningIcon                     from '@material-ui/icons/Warning'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
}

const styles = (theme) => ({
  success: {
    backgroundColor: green[700],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

export default withStyles(styles)((props) => {
  const { onClose, variant, open, className, classes, message, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <SnackbarContent
        className={`${classes[variant]} ${className}`}
        aria-describedby="client-snackbar"
        message={
          (
            <span id="client-snackbar" className={classes.message}>
              <Icon className={`${classes.icon} ${classes.iconVariant}`} />
              {message}
            </span>
          )
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  )
})
